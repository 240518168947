import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.scss";
import { useEffect, useState } from "react";
// importing pages
import NotFound from "./Pages/404";
import AOS from "aos";
import Login from "./Pages/Login";
import Home from "./Pages/Home";
import ProtectedRoute from "./components/Login/ProtectedRoute";
// importing pages

function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <Router>
      <Switch>
        <Route exact path="/login">
          <Login />
        </Route>
        <Route exact path="/*">
          <ProtectedRoute component={Home} />
        </Route>
        <Route exact path="*">
          <NotFound />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
