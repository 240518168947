import "../Styles/home.scss";
import Header from "../components/Util/Header";
import Navigation from "../components/Util/Navigation";
import { useState } from "react";
import Stats from "../components/Stats/Stats";
import { useEffect } from "react";
import Settings from "../components/Settings/Settings";
import Documents from "../components/Documents/Documents";
import NewDocument from "../components/Documents/NewDocument";
import ARC from "../components/Documents/ARC";

export default function Home(props) {
  const [showing, setShowing] = useState(true);
  const pathname = window.location.pathname.split("/");

  const handleResize = () => {
    if (window.innerWidth < 1024) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  return (
    <div className="home">
      <div
        style={{ gridTemplateColumns: !showing ? "auto 1fr" : "250px 1fr" }}
        className="main"
      >
        <div className="left_panel">
          <Navigation showing={showing} setShowing={setShowing} />
        </div>
        <div className="right_panel">
          <Header showing={showing} setShowing={setShowing} />
          <div className="full">
            {pathname[1] === "" && <Stats />}
            {pathname[1] === "resources" && <ARC />}
            {pathname[1] === "new" && <NewDocument />}
            {pathname[1] === "settings" && <Settings />}
          </div>
        </div>
      </div>
    </div>
  );
}
