import "../../Styles/documents.scss";
import Input from "../Reports/ReportsManagement/ReportInput";
import Select from "../Reports/ReportsManagement/ReportSelect";
import { useRef, useState } from "react";
import { useEffect } from "react";
import WaveLoading from "../Util/WaveLoading";

export default function NewDocument(props) {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const types = [
    "Project Documents",
    "Manuals",
    "Reports",
    "Publications",
    "Learning Materials",
  ];
  const departments = [
    "All",
    "Vet Services",
    "Fisheries",
    "Meat Inspection",
    "Crop Extension",
  ];
  const categories = [
    "External",
    "Internal",
  ];
  const rfTitle = useRef();
  const rfType = useRef();
  const rfDescription = useRef();
  const rfKeywords = useRef();
  const rfDate = useRef();
  const rfFile = useRef();
  const rfCategory = useRef();
  const rfPublished = useRef();
  const rfDepartment = useRef();
  const [body, updateBody] = useState({
    Title: null,
    Type: null,
    Description: null,
    Keywords: null,
    Date: null,
    Category: null,
    Department: null,
    Published: null,
    File: null,
  });
  const [showing, setShowing] = useState(true);
  const handleResize = () => {
    if (window.innerWidth < 768) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  const setType = (value) => {
    types.current = value;
  };
  const setDepartments = (value) => {
    types.current = value;
  };
  const setCategories = (value) => {
    types.current = value;
  };

  useEffect(() => {
    handleResize();
  }, []);

  const createDocument = (e) => {
    setError("");
    let d = body;
    d.Title = rfTitle.current.value;
    d.Type = rfType.current.value;
    d.Description = rfDescription.current.value;
    d.Keywords = rfKeywords.current.value;
    d.Date = rfDate.current.value;
    d.Category = rfCategory.current.value;
    d.Department = rfDepartment.current.value;
    d.Published = rfPublished.current.value;
    d.File = rfFile.current.files[0];
    updateBody(d);
    const chck = Object.values(d);
    let valid = true;
    chck.map((item) => {
      if (item === "") {
        valid = false;
      }
    });
    if (!valid) return setError("All fields are required!");
    if (d.File === undefined) return setError("File is required!");
    setLoading(true);

    const formData = new FormData();

    for (const i in body) {
      formData.append(i, body[i]);
    }

    fetch("/api/documents/create", {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
      },
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data.success) {
          console.log(data);
          setError(data.success);
          setTimeout(() => {
            window.location.href = "/resources";
          }, 1000);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setError("Oops! Something went wrong!");
      });
  };

  return (
    <div className="documents">
      <div className="list">
        <h3>New Document</h3>
        <hr />
        <div className="new">
          <form
            autoComplete="none"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="div2equal">
              <Input ref={rfTitle} type="text" label="Title *" />
              <Select
                ref={rfType}
                handleSelection={setType}
                label="Select Type"
                data={types}
              />
            </div>

            <div className="usrinput">
              <h4>Description *</h4>
              <textarea
                id="w3review"
                name="w3review"
                rows="10"
                cols="80"
                ref={rfDescription}
                label="Description *"
                type="textarea"
              />
            </div>

            <div className="div2equal">
              <Input ref={rfKeywords} type="text" label="Keywords *" />
              <Input ref={rfDate} type="date" label="Date *" />
            </div>
            <Select
              ref={rfCategory}
              handleSelection={setCategories}
              label="Category"
              data={categories}
            />
            <div className="div2equal">
              <Select
                ref={rfDepartment}
                handleSelection={setDepartments}
                label="Department"
                data={departments}
              />
              <Input ref={rfPublished} type="date" label="Date Published*" />
            </div>
            <div className="usrinput">
              <h4>Document file *</h4>
              <input
                ref={rfFile}
                type="file"
                label="Upload file *"
                onChange={(e) => setSelectedFile(e.target.files[0])}
                accept=".pdf"
              />
            </div>
            <br />
            <h6>{error}</h6>
            <br />
            <button
              onClick={() => {
                createDocument();
              }}
            >
              Submit
            </button>
          </form>
          {loading && <WaveLoading />}
        </div>
      </div>
    </div>
  );
}
