import { useState } from "react";
import logo from "../../assets/imgs/logo.png";

export default function Navigation(props) {
  const Item = (params) => {
    const [showing, setShowing] = useState(false);

    return (
      <div
        style={{
          backgroundColor: showing ? "#60606010" : "transparent",
        }}
        onMouseLeave={() => {
          setShowing(false);
        }}
      >
        <div
          onClick={() => {
            if (params.options.length === 0) {
              window.location.href = params.url;
            }
            if (params.url == "/logout") {
              localStorage.clear();
              window.location.href = "/login";
            }
          }}
          onMouseEnter={() => {
            setShowing(true);
          }}
          className="item"
          style={{
            padding: params.showing ? "1em" : "5x 0 5px 0",
            gridTemplateColumns: params.showing ? "auto 1fr auto" : "auto",
          }}
        >
          <i aria-hidden="true" className={"fa " + params.icon}>
            {params.code}
          </i>
          {params.showing && <p>{params.txt}</p>}
          {params.options.length > 0 && params.showing && (
            <i className="fa fa-angle-right"></i>
          )}
        </div>
        {showing &&
          params.options.length > 0 &&
          params.options.map((item, i) => {
            return (
              <a key={i} href={params.url + "/" + item}>
                {item}
              </a>
            );
          })}
      </div>
    );
  };

  return (
    <div
      style={{ width: props.showing ? "250px" : "fit-content" }}
      className="navigation"
    >
      <div className="logo">
        <img
          style={{ maxWidth: props.showing ? "70px" : "30px" }}
          src={logo}
          alt=""
        />
        {props.showing && (
          <>
            <h3>KiriAMIS</h3>
            <h3>Agricultural Resource Centre</h3>
            <hr />
          </>
        )}
      </div>
      <Item
        txt="Stats"
        url="/"
        icon="fa-home"
        options={[]}
        showing={props.showing}
      />

      <Item
        txt="Resources"
        url="/resources"
        icon="fa fa-book"
        options={[]}
        showing={props.showing}
      />

      <Item
        txt="New Resource"
        url="/new"
        icon="fa fa-file"
        options={[]}
        showing={props.showing}
      />

      <Item
        txt="Settings"
        url="/settings"
        icon="fa-gear"
        code="&#xf013;"
        options={[]}
        showing={props.showing}
      />
      <Item
        txt="Logout"
        icon="fa-lock"
        url="/logout"
        options={[]}
        showing={props.showing}
      />
    </div>
  );
}
